// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("packs/flash_messages.js")
require("packs/anchor_urls.js")
require("packs/add_active_class.js")

import 'bootstrap/dist/js/bootstrap';
// import 'jquery';

// Found jQuery fix here:
// https://stackoverflow.com/questions/55895494/is-not-defined-when-installing-jquery-in-rails-via-webpack
window.jQuery = $;
window.$ = $;