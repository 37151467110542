"use strict";

$(document).on('turbolinks:load', function() {
	$('#flash-wrapper').hide();

	setTimeout(function(){
		$('#flash-wrapper').fadeIn("slow");
	}, 100)

	setTimeout(function(){
		$('#flash-wrapper').fadeOut("slow", function() {
			$(this).remove();
		})
	}, 3000);
});